<template>
  <div v-show="idCalendar" class="row no-gutters my-3">
    <div class="col border px-0 pb-3 custom-scroll overflow-auto">
        <div class="mx-0 d-flex w-100" :class="ganttMode ? 'width-gantt' : 'width-table'">
            <div class="col-auto px-0 position-sticky bg-white" style="left: 0px; z-index: 1;">
            <div class="row no-gutters border-bottom">
                <div class="border-right width-act d-middle py-1" />
                <div class="border-right width-pro f-500 f-14 d-middle-center cr-default" style="height: 35px;"> Meta </div>
            </div>
            <div class="row no-gutters">
                <div class="border-right width-act d-middle-center py-1">
                <div class="col-8 border text-center bg-grupo f-16 cr-default">
                    <i class="icon-format-list-numbered" />
                    <span class="my-auto ml-1">9 Actividades</span>
                </div>
                </div>
                <div class="border-right width-pro f-500 d-middle-center">
                <div class="d-middle-center px-2 my-auto mx-3 f-12 cr-default"> 2% </div>
                </div>
            </div>
            </div>
            <!-- mes -->
            <template v-if="ganttMode">
            <div v-for="(data, is) in meses" :key="is" class="col-auto d-sm-none d-lg-block px-0 f-500 text-center">
                <div class="row no-gutters border-bottom border-right">
                <div class="col f-500 d-middle-center py-2 text-capitalize" style="height: 35px;">
                    {{data.mes}}
                </div>
                </div>
                <div class="d-flex mx-0 h-50">
                <div v-for="dia in data.dias" :key="`day-${dia}`" class="width-day-num f-10 d-middle-center border-right" 
                :style="`${zoomCalendar ? 'min-width: 15px' : 'min-width: 19px'}`">
                    {{dia}}
                </div>
                </div>
            </div>
            </template>
            <template v-else>
                <div class="d-sm-none d-lg-flex col px-0 position-sticky bg-white cr-default" style="left:0; z-index:0;">
                    <div v-for="(data, d) in vistaTabla" :key="d" class="border-right w-100 f-600 text-center">
                        <div class="row mx-0 d-middle-center border-bottom" style="height:36px;min-width:162px">
                            {{data}}
                        </div>
                        <div class="row mx-0" />
                    </div>
                </div>
            </template>
        </div>
        <draggable tag="div" :list="calenHijMapped" class="list-areas" handle=".handle">
            <div v-for="(element, idx) in calenHijMapped" :key="idx" class="border-bottom" :class="`grupo_${element.id} ` +(idx!=calenHij.length-1 || idx!=calenHij.length===0 ? 'mb-3':'')">
                <!-- cabecera total dias -->
                <div class="d-middle mx-0 bg-grupo-borde" :class="ganttMode ? 'width-gantt' : 'width-table'">
                    <div class="col-auto border-right width-act d-middle position-sticky bg-grupo">
                        <!-- v-if="$can('boton.proyectos.ver.actividades')" -->
                        <i class="icon-menu f-15 handle mx-1" style="cursor: ns-resize;" /> 
                        <i v-show="calenHij[idx].actividades.length>0" :class="`icon-${element.desplegable ?'up-open':'down-open'} cr-pointer`" />
                        <p class="tres-puntos col-8 cr-pointer my-auto ml-1 px-0">{{element.nombre}}</p>
                        <!-- v-if="$can('boton.proyectos.ver.actividades')" -->
                        <el-tooltip content="Crear actividad" effect="light" placement="bottom" visible-arrow>
                            <i class="hover-add icon-plus-box f-19 cr-pointer ml-auto" @click="crearActividad" />
                        </el-tooltip>
                        <el-tooltip placement="bottom-start" effect="light" visible-arrow popper-class="like-buttons">
                            <template #content>
                                <p class="p-2 cr-pointer item" @click="editarGrupoActividades(element.nombre)">Editar</p>
                                <p class="p-2 cr-pointer item" @click="eliminarGrupoActividades">Eliminar</p>
                            </template>
                            <i class="icon-dots-vertical f-20 cr-pointer" />
                        </el-tooltip>
                    </div>
                    <div class="col-auto border-right width-pro f-600 bg-grupo d-middle-center position-sticky h-26 cr-default">
                        {{element.porcentaje}}%
                    </div>
                    <div class="d-sm-none d-lg-flex col d-middle px-0 f-600 text-center bg-grupo h-26">
                        <div v-if="ganttMode" class="h-100 d-flex flex-column justify-content-around">
                            <div class="d-flex">
                                <div class="bar" :style="`background: transparent; width: ${ (zoomCalendar ? 15 : 19) * element.inicio_ejecucion}px;`" />
                                <div class="bar-container " :style="` width: ${ (zoomCalendar ? 15 : 19) * (element.fin_ejecucion - element.inicio_ejecucion)}px;`">
                                    <div class="bar bg-gris-oscuro" />
                                    <div class="percentage bg-gris-oscuro" :style="`width: ${element.porcentaje}%;`" />
                                </div>
                                <div class="bar" :style="`width: ${((zoomCalendar ? 15 : 19) * totalDias) - (((zoomCalendar ? 15 : 19) * element.inicio_ejecucion) + ((zoomCalendar ? 15 : 19) * (element.fin_ejecucion - element.inicio_ejecucion)))}px;`" />
                            </div>
                            <div class="d-flex">
                                <div class="bar" :style="`background: transparent; width: ${(zoomCalendar ? 15 : 19) * element.inicio}px;`" />
                                <div class="bar-container" :style="`width: ${(zoomCalendar ? 15 : 19) * (element.fin - element.inicio)}px;`">
                                    <div class="bar" :style="`background: ${element.barra_color}; `" />
                                    <div class="percentage" :style="`background: ${element.barra_color}; width: ${element.porcentaje}%;`" />
                                </div>
                                <div class="bar" :style="`width: ${((zoomCalendar ? 15 : 19) * totalDias) - (((zoomCalendar ? 15 : 19) * element.inicio) + ((zoomCalendar ? 15 : 19) * (element.fin - element.inicio)))}px;`" />
                            </div>
                        </div>
                        <div v-else class="d-sm-none d-lg-flex col px-0 position-sticky" style="left:0; z-index:0; align-selft: flex-start;">
                            <div v-for="(data, d) in vistaTabla" :key="d" class="border-right w-100 f-600 text-center">
                                <div class="row mx-0 justify-center align-items-center border-bottom" style="height:36px;min-width:162px" />
                            </div>
                        </div>
                    </div>
                    <div class="d-sm-block d-lg-none col d-middle text-center">
                        <span class="bg-general p-1 px-2 br-5 f-12 mr-2 text-white">300</span>25 Nov 2020 - 26 Dic 2020
                    </div>
                </div>
                <draggable v-show="element.desplegable" tag="div" :list="element.actividades" class="list-areas fit-content" handle=".handle">
                    <div v-for="(hijo, idxHijo) in element.actividades" :key="`${idxHijo}`" :class="`actividad_${hijo.id}`">
                        <div class="d-middle mx-0" :class="ganttMode ? 'width-gantt' : 'width-table'">
                            <div class="col-auto border-right width-act d-middle position-sticky bg-white border-bottom pr-0" style="padding-left: 2.5rem !important">
                                <!-- v-if="$can('boton.proyectos.ver.actividades')" -->
                                <i  class="icon-menu f-15 handle mx-1" style="cursor: ns-resize;" />
                                <i v-show="calenHij[idx].actividades[idxHijo].sub_actividades.length>0" :class="`icon-${hijo.desplegable ?'up-open':'down-open'} f-20 cr-pointer`" />
                                <p class="tres-puntos my-auto mx-1 cr-pointer">{{hijo.nombre}}</p>
                                <el-tooltip placement="bottom-start" effect="light" visible-arrow popper-class="like-buttons">
                                    <template #content>
                                        <p class="p-2 cr-pointer item" @click="editarActividad">Editar</p>
                                        <p class="p-2 cr-pointer item" @click="eliminarActividad">Eliminar</p>
                                    </template>
                                    <i class="icon-dots-vertical f-20 cr-pointer ml-auto" />
                                </el-tooltip>
                            </div>
                            <div class="col-auto border-right width-pro f-600 justify-center d-middle position-sticky bg-white border-bottom h-26">
                                <!-- pendiente terminar de ajustar para los parámetros Avisar a Jose Luis Moreno-->
                                <div v-if="hijo.sub_actividades.length===0" class="d-middle px-2 mx-3 br-4 justify-center">
                                    {{hijo.porcentaje}}%
                                </div>
                                <div v-else class="d-middle px-2 mx-3 br-4 justify-center cr-default">
                                    {{hijo.porcentaje}}%
                                </div>
                            </div>
                            <div class="d-sm-none d-lg-block col d-middle px-0 f-600 text-center bg-whitesmoke border-bottom h-26">
                                <div v-if="ganttMode" class="h-100 d-flex flex-column justify-content-around">
                                    <div class="d-flex">
                                        <div class="bar" :style="`background: transparent; width: ${19 * hijo.inicio_ejecucion}px;`" />
                                        <div class="bar-container " :style="`width: ${19 * (hijo.fin_ejecucion - hijo.inicio_ejecucion)}px;`">
                                            <div class="bar bg-gris-oscuro" />
                                            <div class="percentage bg-gris-oscuro" :style="`width: ${hijo.porcentaje}%;`" />
                                        </div>
                                        <div class="bar" :style="`width: ${(19 * totalDias) - ((19 * hijo.inicio_ejecucion) + (19 * (hijo.fin_ejecucion - hijo.inicio_ejecucion)))}px;`" />
                                    </div>
                                    <div class="d-flex">
                                        <div class="bar" :style="`background: transparent; width: ${19 * hijo.inicio}px;`" />
                                        <div class="bar-container" :style="`width: ${19 * (hijo.fin - hijo.inicio)}px;`">
                                            <div class="bar" :style="`background: ${hijo.barra_color}; `" />
                                            <div class="percentage" :style="`background: ${hijo.barra_color}; width: ${hijo.porcentaje}%;`" />
                                        </div>
                                        <div class="bar" :style="`width: ${(19 * totalDias) - ((19 * hijo.inicio) + (19 * (hijo.fin - hijo.inicio)))}px;`" />
                                    </div>
                                </div>
                                <div v-else class="d-flex px-0 position-sticky bg-white" style="left:0; z-index:0; align-selft: flex-start;">
                                    <div class="border-right f-600 px-3 py-1 width-fechas">
                                        {{hijo.fecha_inicio_ejecucion}}
                                    </div>
                                    <div class="border-right f-600 px-3 py-1 width-fechas">
                                        {{hijo.fecha_fin_ejecucion}}
                                    </div>
                                    <div class="border-right f-600 px-3 py-1 width-fechas">
                                        {{hijo.fecha_inicio}}
                                    </div>
                                    <div class="border-right f-600 px-3 py-1 width-fechas">
                                        {{hijo.fecha_fin}}
                                    </div>
                                    <div class="border-right f-600 px-3 py-1 width-fechas">
                                        {{hijo.desface_dias}}
                                    </div>
                                    <div class="border-right f-600 px-3 py-1 width-fechas">
                                        {{hijo.desface_fin}}
                                    </div>
                                </div>
                            </div>
                            <div class="d-sm-block d-lg-none col d-middle text-center">
                                <span class="bg-general p-1 px-2 br-5 f-12 mr-2 text-white">300</span>25 Nov 2020 - 26 Dic 2020
                            </div>
                        </div>
                        <draggable v-show="hijo.desplegable" tag="div" :list="hijo.sub_actividades" class="list-areas" handle=".handle">
                            <div v-for="(nieto, idxNieto) in hijo.sub_actividades" :key="`${idxNieto}`" class="d-middle mx-0 hover-options" :class="ganttMode ? 'width-gantt' : 'width-table'">
                                <div class="col-auto border-right border-bottom width-act d-middle position-sticky bg-white" style="padding-left: 5rem !important;">
                                    <!-- v-if="$can('boton.proyectos.ver.actividades') && calendario_seleccionado.fecha_inicio_ejecucion" -->
                                    <div class="col-2 d-middle justify-center cr-pointer">
                                        <i class="mdi mdi-message-text f-18 text-muted" />
                                        <span class="bg-danger text-white px-1 br-20 f-10">{{nieto.comentarios_count}}</span>
                                    </div>
                                    <!-- v-if="$can('boton.proyectos.ver.actividades')"  -->
                                    <i class="icon-menu f-22 cr-pointer handle mx-1" style="cursor: ns-resize;" />
                                    <p class="tres-puntos my-auto mx-1">{{nieto.nombre}}</p>
                                    <el-popover v-model="nieto.popover" placement="bottom" width="120" trigger="click" class="ml-auto">
                                        <div class="row no-gutters hover-options br-3 p-1">
                                            Crear pendiente
                                        </div>
                                        <div class="row no-gutters hover-options br-3 p-1">
                                            Editar
                                        </div>
                                        <div class="row no-gutters hover-options br-3 p-1">
                                            Asignar
                                        </div>
                                        <div class="row no-gutters hover-options br-3 p-1">
                                            Eliminar
                                        </div>
                                        <!-- v-if="$can('boton.proyectos.ver.actividades')" -->
                                        <i slot="reference" class="icon-dots-vertical f-20 cr-pointer" />
                                    </el-popover>
                                </div>
                                <div class="col-auto border-right width-pro f-600 justify-center d-middle position-sticky bg-white border-bottom h-26 cr-default">
                                    <!-- pendiente terminar de ajustar para los parámetros Avisar a Jose Luis Moreno-->
                                    <div class="d-middle px-2 mx-3 br-4 justify-center">
                                        {{nieto.porcentaje}}%
                                    </div>
                                </div>
                                <div class="d-sm-none d-lg-block col d-middle px-0 f-600 text-center bg-whitesmoke border-bottom h-26">
                                    <div v-if="ganttMode" class="h-100 d-flex flex-column justify-content-around">
                                        <div class="d-flex">
                                            <div class="bar" :style="`background: transparent; width: ${19 * nieto.inicio_ejecucion}px;`" />
                                            <div class="bar-container " :style="`width: ${19 * (nieto.fin_ejecucion - nieto.inicio_ejecucion)}px;`">
                                                <div class="bar bg-gris-oscuro" />
                                                <div class="percentage bg-gris-oscuro" :style="`width: ${nieto.porcentaje}%;`" />
                                            </div>
                                            <div class="bar" :style="`width: ${(19 * totalDias) - ((19 * nieto.inicio_ejecucion) + (19 * (nieto.fin_ejecucion - nieto.inicio_ejecucion)))}px;`" />
                                        </div>
                                        <div class="d-flex">
                                            <div class="bar" :style="`background: transparent; width: ${19 * nieto.inicio}px;`" />
                                            <div class="bar-container" :style="`width: ${19 * (nieto.fin - nieto.inicio)}px;`">
                                                <div class="bar" :style="`background: ${nieto.barra_color}; `" />
                                                <div class="percentage" :style="`background: ${nieto.barra_color}; width: ${nieto.porcentaje}%;`" />
                                            </div>
                                            <div class="bar" :style="`width: ${(19 * totalDias) - ((19 * nieto.inicio) + (19 * (nieto.fin - nieto.inicio)))}px;`" />
                                        </div>
                                    </div>
                                    <div v-else class="d-flex px-0 position-sticky bg-white" style="left:0; z-index:0; cursor:auto;">
                                        <div class="border-right f-600 px-3 py-1 width-fechas">
                                            {{nieto.fecha_inicio_ejecucion}}
                                        </div>
                                        <div class="border-right f-600 px-3 py-1 width-fechas">
                                            {{nieto.fecha_fin_ejecucion}}
                                        </div>
                                        <div class="border-right f-600 px-3 py-1 width-fechas">
                                            {{nieto.fecha_inicio}}
                                        </div>
                                        <div class="border-right f-600 px-3 py-1 width-fechas">
                                            {{nieto.fecha_fin}}
                                        </div>
                                        <div class="border-right f-600 px-3 py-1 width-fechas">
                                            {{nieto.desface_dias}}
                                        </div>
                                        <div class="border-right f-600 px-3 py-1 width-fechas">
                                            {{nieto.desface_fin}}
                                        </div>
                                    </div>
                                </div>
                                <div class="d-sm-block d-lg-none col d-middle text-center">
                                    <span class="bg-general p-1 px-2 br-5 f-12 mr-2 text-white">300</span>25 Nov 2020 - 26 Dic 2020
                                </div>
                            </div>
                        </draggable>
                    </div>
                </draggable>
            </div>
        </draggable>
    </div>
    <!-- partials -->
    <modal-crear-actividad ref="crearActividad" />
    <modal-editar-grupo-actividades ref="editarGrupoActividades" />
    <modal-editar-actividad ref="editarActividad" />
    <modal-eliminar ref="eliminarGrupoActividades" title="Eliminar grupo de actividades" mensaje="¿Está seguro que quiere eliminar este grupo de actividades?" />
    <modal-eliminar ref="eliminarActividad" title="Eliminar actividad" mensaje="¿Está seguro que quiere eliminar esta actividad?" />
  </div>
</template>

<script>
export default {
    props:{
        zoomCalendar:{
            type: Boolean,
            required: true
        }
    },
    components:{
        modalCrearActividad: ()=> import('../partials/modalCrearActividad.vue'),
        modalEditarGrupoActividades: ()=> import('../partials/modalEditarGrupoActividades.vue'),
        modalEditarActividad: ()=> import('../partials/modalEditarActividad.vue'),
    },
    data(){
        return{
            ganttMode: true,
            zoom: false,
            idCalendar: 1,
            totalDias:258,
            nombre: "",
            meses:[
                {mes:"abril 2021",dias:[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30]},
                {mes:"mayo 2021",dias:[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31]},
                {mes:"junio 2021",dias:[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30]},
                {mes:"julio 2021",dias:[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31]},
                {mes:"agosto 2021",dias:[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31]},
                {mes:"septiembre 2021",dias:[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30]},
                {mes:"octubre 2021",dias:[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31]},
                {mes:"noviembre 2021",dias:[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30]},
                {mes:"diciembre 2021",dias:[1,2,3,4,5,6,7,8,9,10,11,12,13,14]}
            ],
            vistaTabla:["Teórico inicial","Teórico final","Real inicial","Real final","Desface duración","Desface fin"],
            calenHijMapped:[
                { 
                    id: 1677,
                    estado:0,
                    nivel:2,
                    id_padre:1676,
                    calendario_principal:null,
                    calendario_visible:null,
                    nombre:"Requerimientos",
                    posicion:1,
                    descripcion:null,
                    fecha_inicio:null,
                    fecha_fin:null,
                    fecha_inicio_ejecucion:null,
                    fecha_fin_ejecucion:null,
                    id_actividad_predecesora:null,
                    predecesora_duracion:null,
                    predecesora_dias_fin:null,
                    meta_unidad:null,
                    meta_cantidad:null,
                    porcentaje:10,
                    barra_color:"#EF2A2A",
                    id_proyecto:null,
                    calendario_sugerido:1,
                    dia_inicio:null,
                    dias_duracion:null,
                    timeline:0,
                    created_by:1,
                    updated_by:null,
                    created_at:"2021-04-16 15:24:29",
                    updated_at:"2021-05-13 10:20:32",
                    deleted_at:null,
                    inicio:15,
                    fin:17,
                    inicio_ejecucion:0,
                    fin_ejecucion:30,
                    hasta:15,
                    hasta_ejecucion:3,
                    actividades:[],
                    popover:false,
                    popoverInterno:false,
                    desplegable:true,
                    actividades:[
                        {
                            id:1679,
                            estado:0,
                            nivel:3,
                            id_padre:1677,
                            calendario_principal:null,
                            calendario_visible:null,
                            nombre:"Desarrollo De Documento Requerimientos Generales",
                            posicion:1,
                            descripcion:"Desarrollo de versión preliminar de requisitos.",
                            fecha_inicio:"2021-04-06",
                            fecha_fin:"2021-04-08",
                            fecha_inicio_ejecucion:"2021-04-16",
                            fecha_fin_ejecucion:"2021-04-18",
                            id_actividad_predecesora:null,
                            predecesora_duracion:null,
                            predecesora_dias_fin:null,
                            meta_unidad:null,
                            meta_cantidad:null,
                            porcentaje:0,
                            barra_color:"#A356A3",
                            id_proyecto:null,
                            calendario_sugerido:1,
                            dia_inicio:null,
                            dias_duracion:null,
                            timeline:0,
                            created_by:1,
                            updated_by:null,
                            created_at:"2021-04-16 15:28:21",
                            updated_at:"2021-04-26 10:31:11",
                            deleted_at:null,
                            encargados_count:0,
                            comentarios_count:0,
                            inicio:5,
                            fin:7,
                            inicio_ejecucion:15,
                            fin_ejecucion:17,
                            hasta:5,
                            hasta_ejecucion:15,
                            resto_encargados:-3,
                            usuarios:[],
                            sub_actividades:[],
                            popover:false,
                            ind:0,
                            desface_dias:0,
                            desface_fin:10,
                            desplegable:true
                        },
                        {
                            id:1678,
                            estado:0,
                            nivel:3,
                            id_padre:1677,
                            calendario_principal:null,
                            calendario_visible:null,
                            nombre:"Visita Inicial",
                            posicion:2,
                            descripcion:"Primer reunión de equipo, asisten: Sponsor, Product Owner y StakeHolders finales.",
                            fecha_inicio:"2021-04-01",
                            fecha_fin:"2021-04-03",
                            fecha_inicio_ejecucion:"2021-04-16",
                            fecha_fin_ejecucion:"2021-04-18",
                            id_actividad_predecesora:null,
                            predecesora_duracion:null,
                            predecesora_dias_fin:null,
                            meta_unidad:null,
                            meta_cantidad:null,
                            porcentaje:0,
                            barra_color:"#57B860",
                            id_proyecto:null,
                            calendario_sugerido:1,
                            dia_inicio:null,
                            dias_duracion:null,
                            timeline:0,
                            created_by:1,
                            updated_by:null,
                            created_at:"2021-04-16 15:26:25",
                            updated_at:"2021-04-26 10:31:11",
                            deleted_at:null,
                            encargados_count:1,
                            comentarios_count:0,
                            inicio:0,
                            fin:2,
                            inicio_ejecucion:15,
                            fin_ejecucion:17,
                            hasta:0,
                            hasta_ejecucion:15,
                            resto_encargados:-2,
                            usuarios:[
                                {
                                id:168,
                                tipo:1,
                                nombre:"Luz Torres",
                                foto:"funcionarios/sZ4QMI7EtJZeWCIn6ProjqNzAdlTWCoTkQvST8R8.jpeg",
                                foto_miniatura:"funcionarios/N3yVbRnFLLQo0tHphf0F1s8OBb1l0cRpAlxKe3gO.png",
                                correo:"gerentesoportetecnico@essi.com.co",
                                cargo:"Gerente Soporte Técnico y Repuestos"
                                }
                            ],
                            sub_actividades:[],
                            popover:false,
                            ind:0,
                            desface_dias:0,
                            desface_fin:15,
                            desplegable:false
                        },
                        {
                        id:1690,
                        estado:0,
                        nivel:3,
                        id_padre:1677,
                        calendario_principal:null,
                        calendario_visible:null,
                        nombre:"Act3",
                        posicion:3,
                        descripcion:"asdasd",
                        fecha_inicio:"2021-04-26",
                        fecha_fin:"2021-05-01",
                        fecha_inicio_ejecucion:"2021-04-01",
                        fecha_fin_ejecucion:"2021-04-01",
                        id_actividad_predecesora:null,
                        predecesora_duracion:null,
                        predecesora_dias_fin:null,
                        meta_unidad:null,
                        meta_cantidad:null,porcentaje:30,barra_color:"#953838",
                        id_proyecto:null,
                        calendario_sugerido:1,
                        dia_inicio:null,
                        dias_duracion:null,
                        timeline:0,
                        created_by:1,
                        updated_by:null,
                        created_at:"2021-04-26 10:30:54",
                        updated_at:"2021-05-13 10:20:32",
                        deleted_at:null,
                        encargados_count:1,
                        comentarios_count:0,
                        inicio:25,
                        fin:30,
                        inicio_ejecucion:0,
                        fin_ejecucion:0,
                        hasta:27,
                        hasta_ejecucion:0,
                        resto_encargados:-2,
                        usuarios:[
                            { 
                            id:168,
                            tipo:1,
                            nombre:"Luz Torres",
                            foto:"funcionarios/sZ4QMI7EtJZeWCIn6ProjqNzAdlTWCoTkQvST8R8.jpeg",
                            foto_miniatura:"funcionarios/N3yVbRnFLLQo0tHphf0F1s8OBb1l0cRpAlxKe3gO.png",
                            correo:"gerentesoportetecnico@essi.com.co",
                            cargo:"Gerente Soporte Técnico y Repuestos"
                            }
                        ],
                        sub_actividades:[],
                        popover:false,
                        ind:0,
                        desface_dias:5,
                        desface_fin:30,
                        desplegable:false
                        }
                    ],
                },
                { 
                    id: 1680,
                    estado:0,
                    nivel:2,
                    id_padre:1676,
                    calendario_principal:null,
                    calendario_visible:null,
                    nombre:"Diseño UX/UI",
                    posicion:2,
                    descripcion:null,
                    fecha_inicio:null,
                    fecha_fin:null,
                    fecha_inicio_ejecucion:null,
                    fecha_fin_ejecucion:null,
                    id_actividad_predecesora:null,
                    predecesora_duracion:null,
                    predecesora_dias_fin:null,
                    meta_unidad:null,
                    meta_cantidad:null,
                    porcentaje:0,
                    barra_color:"#7CD697",
                    id_proyecto:null,
                    calendario_sugerido:1,
                    dia_inicio:null,
                    dias_duracion:null,
                    timeline:0,
                    created_by:1,
                    updated_by:null,
                    created_at:"2021-04-16 15:29:22",
                    updated_at:"2021-04-26 10:53:37",
                    deleted_at:null,
                    inicio:15,
                    fin:17,
                    inicio_ejecucion:255,
                    fin_ejecucion:256,
                    hasta:15,
                    hasta_ejecucion:255,
                    actividades:[],
                    popover:false,
                    popoverInterno:false,
                    desplegable:false
                },
                { 
                    id: 1682,
                    estado:0,
                    nivel:2,
                    id_padre:1676,
                    calendario_principal:null,
                    calendario_visible:null,
                    nombre:"FontEnd-Work",
                    posicion:3,
                    descripcion:null,
                    fecha_inicio:null,
                    fecha_fin:null,
                    fecha_inicio_ejecucion:null,
                    fecha_fin_ejecucion:null,
                    id_actividad_predecesora:null,
                    predecesora_duracion:null,
                    predecesora_dias_fin:null,
                    meta_unidad:null,
                    meta_cantidad:null,
                    porcentaje:0,
                    barra_color:"#0b56a7",
                    id_proyecto:null,
                    calendario_sugerido:1,
                    dia_inicio:null,
                    dias_duracion:null,
                    timeline:0,
                    created_by:1,
                    updated_by:null,
                    created_at:"2021-04-16 15:30:53",
                    updated_at:"2021-04-16 15:30:53",
                    deleted_at:null,
                    inicio:15,
                    fin:19,
                    inicio_ejecucion:6,
                    fin_ejecucion:10,
                    hasta:15,
                    hasta_ejecucion:6,
                    actividades:[],
                    popover:false,
                    popoverInterno:false,
                    desplegable:false
                },
                {
                    id:1683,
                    estado:0,
                    nivel:2,
                    id_padre:1676,
                    calendario_principal:null,
                    calendario_visible:null,
                    nombre:"BackEnd-Work",
                    posicion:4,
                    descripcion:null,
                    fecha_inicio:null,
                    fecha_fin:null,
                    fecha_inicio_ejecucion:null,
                    fecha_fin_ejecucion:null,
                    id_actividad_predecesora:null,
                    predecesora_duracion:null,
                    predecesora_dias_fin:null,
                    meta_unidad:null,
                    meta_cantidad:null,
                    porcentaje:0,
                    barra_color:"#0b56a7",
                    id_proyecto:null,
                    calendario_sugerido:1,
                    dia_inicio:null,
                    dias_duracion:null,
                    timeline:0,
                    created_by:1,
                    updated_by:null,
                    created_at:"2021-04-16 15:31:06",
                    updated_at:"2021-04-16 15:31:06",
                    deleted_at:null,
                    inicio:15,
                    fin:20,
                    inicio_ejecucion:11,
                    fin_ejecucion:16,
                    hasta:15,
                    hasta_ejecucion:11,
                    actividades:[],
                    popover:false,
                    popoverInterno:false,
                    desplegable:false
                },
                {
                    id:1686,
                    estado:0,
                    nivel:2,
                    id_padre:1676,
                    calendario_principal:null,
                    calendario_visible:null,
                    nombre:"Deploy",
                    posicion:5,
                    descripcion:null,
                    fecha_inicio:null,
                    fecha_fin:null,
                    fecha_inicio_ejecucion:null,
                    fecha_fin_ejecucion:null,
                    id_actividad_predecesora:null,
                    predecesora_duracion:null,
                    predecesora_dias_fin:null,
                    meta_unidad:null,
                    meta_cantidad:null,
                    porcentaje:0,
                    barra_color:"#0b56a7",
                    id_proyecto:null,
                    calendario_sugerido:1,
                    dia_inicio:null,
                    dias_duracion:null,
                    timeline:0,
                    created_by:1,
                    updated_by:null,
                    created_at:"2021-04-16 15:32:41",
                    updated_at:"2021-04-16 15:32:41",
                    deleted_at:null,
                    inicio:15,
                    fin:19,
                    inicio_ejecucion:15,
                    fin_ejecucion:19,
                    hasta:15,
                    hasta_ejecucion:15,
                    actividades:[],
                    popover:false,
                    popoverInterno:false,
                    desplegable:false
                },
            ],
            calenHij: [
                {
                    id:1677,
                    estado:0,
                    nivel:2,
                    id_padre:1676,
                    calendario_principal:null,
                    calendario_visible:null,
                    nombre:"Requerimientos",
                    posicion:1,
                    descripcion:null,
                    fecha_inicio:null,
                    fecha_fin:null,
                    fecha_inicio_ejecucion:null,
                    fecha_fin_ejecucion:null,
                    id_actividad_predecesora:null,
                    predecesora_duracion:null,
                    predecesora_dias_fin:null,
                    meta_unidad:null,
                    meta_cantidad:null,
                    porcentaje:10,
                    barra_color:"#0b56a7",
                    id_proyecto:null,
                    calendario_sugerido:1,
                    dia_inicio:null,
                    dias_duracion:null,
                    timeline:0,
                    created_by:1,
                    updated_by:null,
                    created_at:"2021-04-16 15:24:29",
                    updated_at:"2021-05-13 10:20:32",
                    deleted_at:null,
                    inicio:15,
                    fin:17,
                    inicio_ejecucion:0,
                    fin_ejecucion:30,
                    hasta:15,
                    hasta_ejecucion:3,
                    actividades:[
                        {
                            id:1679,
                            estado:0,
                            nivel:3,
                            id_padre:1677,
                            calendario_principal:null,
                            calendario_visible:null,
                            nombre:"Desarrollo De Documento Requerimientos Generales",
                            posicion:1,
                            descripcion:"Desarrollo de versión preliminar de requisitos.",
                            fecha_inicio:"2021-04-06",
                            fecha_fin:"2021-04-08",
                            fecha_inicio_ejecucion:"2021-04-16",
                            fecha_fin_ejecucion:"2021-04-18",
                            id_actividad_predecesora:null,
                            predecesora_duracion:null,
                            predecesora_dias_fin:null,
                            meta_unidad:null,
                            meta_cantidad:null,
                            porcentaje:0,
                            barra_color:"#A356A3",
                            id_proyecto:null,
                            calendario_sugerido:1,
                            dia_inicio:null,
                            dias_duracion:null,
                            timeline:0,
                            created_by:1,
                            updated_by:null,
                            created_at:"2021-04-16 15:28:21",
                            updated_at:"2021-04-26 10:31:11",
                            deleted_at:null,
                            encargados_count:0,
                            comentarios_count:0,
                            inicio:5,
                            fin:7,
                            inicio_ejecucion:15,
                            fin_ejecucion:17,
                            hasta:5,
                            hasta_ejecucion:15,
                            resto_encargados:-3,
                            usuarios:[],
                            sub_actividades:[],
                            popover:false,
                            ind:0,
                            desface_dias:0,
                            desface_fin:10,
                            desplegable:true
                        },
                        {
                            id:1678,
                            estado:0,
                            nivel:3,
                            id_padre:1677,
                            calendario_principal:null,
                            calendario_visible:null,
                            nombre:"Visita Inicial",
                            posicion:2,
                            descripcion:"Primer reunión de equipo, asisten: Sponsor, Product Owner y StakeHolders finales.",
                            fecha_inicio:"2021-04-01",
                            fecha_fin:"2021-04-03",
                            fecha_inicio_ejecucion:"2021-04-16",
                            fecha_fin_ejecucion:"2021-04-18",
                            id_actividad_predecesora:null,
                            predecesora_duracion:null,
                            predecesora_dias_fin:null,
                            meta_unidad:null,
                            meta_cantidad:null,
                            porcentaje:0,
                            barra_color:"#57B860",
                            id_proyecto:null,
                            calendario_sugerido:1,
                            dia_inicio:null,
                            dias_duracion:null,
                            timeline:0,
                            created_by:1,
                            updated_by:null,
                            created_at:"2021-04-16 15:26:25",
                            updated_at:"2021-04-26 10:31:11",
                            deleted_at:null,
                            encargados_count:1,
                            comentarios_count:0,
                            inicio:0,
                            fin:2,
                            inicio_ejecucion:15,
                            fin_ejecucion:17,
                            hasta:0,
                            hasta_ejecucion:15,
                            resto_encargados:-2,
                            usuarios:[
                                {
                                id:168,
                                tipo:1,
                                nombre:"Luz Torres",
                                foto:"funcionarios/sZ4QMI7EtJZeWCIn6ProjqNzAdlTWCoTkQvST8R8.jpeg",
                                foto_miniatura:"funcionarios/N3yVbRnFLLQo0tHphf0F1s8OBb1l0cRpAlxKe3gO.png",
                                correo:"gerentesoportetecnico@essi.com.co",
                                cargo:"Gerente Soporte Técnico y Repuestos"
                                }
                            ],
                            sub_actividades:[],
                            popover:false,
                            ind:0,
                            desface_dias:0,
                            desface_fin:15,
                            desplegable:false
                        },
                        {
                        id:1690,
                        estado:0,
                        nivel:3,
                        id_padre:1677,
                        calendario_principal:null,
                        calendario_visible:null,
                        nombre:"Act3",
                        posicion:3,
                        descripcion:"asdasd",
                        fecha_inicio:"2021-04-26",
                        fecha_fin:"2021-05-01",
                        fecha_inicio_ejecucion:"2021-04-01",
                        fecha_fin_ejecucion:"2021-04-01",
                        id_actividad_predecesora:null,
                        predecesora_duracion:null,
                        predecesora_dias_fin:null,
                        meta_unidad:null,
                        meta_cantidad:null,porcentaje:30,barra_color:"#953838",
                        id_proyecto:null,
                        calendario_sugerido:1,
                        dia_inicio:null,
                        dias_duracion:null,
                        timeline:0,
                        created_by:1,
                        updated_by:null,
                        created_at:"2021-04-26 10:30:54",
                        updated_at:"2021-05-13 10:20:32",
                        deleted_at:null,
                        encargados_count:1,
                        comentarios_count:0,
                        inicio:25,
                        fin:30,
                        inicio_ejecucion:0,
                        fin_ejecucion:0,
                        hasta:27,
                        hasta_ejecucion:0,
                        resto_encargados:-2,
                        usuarios:[
                            { 
                            id:168,
                            tipo:1,
                            nombre:"Luz Torres",
                            foto:"funcionarios/sZ4QMI7EtJZeWCIn6ProjqNzAdlTWCoTkQvST8R8.jpeg",
                            foto_miniatura:"funcionarios/N3yVbRnFLLQo0tHphf0F1s8OBb1l0cRpAlxKe3gO.png",
                            correo:"gerentesoportetecnico@essi.com.co",
                            cargo:"Gerente Soporte Técnico y Repuestos"
                            }
                        ],
                        sub_actividades:[],
                        popover:false,
                        ind:0,
                        desface_dias:5,
                        desface_fin:30,
                        desplegable:false
                        }
                    ],
                    popover:false,
                    popoverInterno:false,
                    desplegable:false
                },
                {
                    "id":1682,
                    "estado":0,
                    "nivel":2,
                    "id_padre":1676,
                    "calendario_principal":null,
                    "calendario_visible":null,
                    "nombre":"FontEnd-Work",
                    "posicion":3,
                    "descripcion":null,
                    "fecha_inicio":null,
                    "fecha_fin":null,
                    "fecha_inicio_ejecucion":null,
                    "fecha_fin_ejecucion":null,
                    "id_actividad_predecesora":null,
                    "predecesora_duracion":null,
                    "predecesora_dias_fin":null,
                    "meta_unidad":null,
                    "meta_cantidad":null,
                    "porcentaje":0,
                    "barra_color":"#0b56a7",
                    "id_proyecto":null,
                    "calendario_sugerido":1,
                    "dia_inicio":null,
                    "dias_duracion":null,
                    "timeline":0,
                    "created_by":1,
                    "updated_by":null,
                    "created_at":"2021-04-16 15:30:53",
                    "updated_at":"2021-04-16 15:30:53",
                    "deleted_at":null,
                    "inicio":15,
                    "fin":19,
                    "inicio_ejecucion":6,
                    "fin_ejecucion":10,
                    "hasta":15,
                    "hasta_ejecucion":6,
                    "actividades":[
                        {
                        "id":1684,
                        "estado":0,
                        "nivel":3,
                        "id_padre":1682,
                        "calendario_principal":null,
                        "calendario_visible":null,
                        "nombre":"Fronting",
                        "posicion":1,
                        "descripcion":"FontEnd trabajando.",
                        "fecha_inicio":"2021-04-07",
                        "fecha_fin":"2021-04-11",
                        "fecha_inicio_ejecucion":"2021-04-16",
                        "fecha_fin_ejecucion":"2021-04-20",
                        "id_actividad_predecesora":null,
                        "predecesora_duracion":null,
                        "predecesora_dias_fin":null,
                        "meta_unidad":null,
                        "meta_cantidad":null,
                        "porcentaje":0,
                        "barra_color":"#E7AD14",
                        "id_proyecto":null,
                        "calendario_sugerido":1,
                        "dia_inicio":null,
                        "dias_duracion":null,
                        "timeline":0,
                        "created_by":1,
                        "updated_by":null,
                        "created_at":"2021-04-16 15:31:47",
                        "updated_at":"2021-04-16 15:34:55",
                        "deleted_at":null,
                        "encargados_count":0,
                        "comentarios_count":0,
                        "inicio":6,
                        "fin":10,
                        "inicio_ejecucion":15,
                        "fin_ejecucion":19,
                        "hasta":6,
                        "hasta_ejecucion":15,
                        "resto_encargados":-3,
                        "usuarios":[
                            
                        ],
                        "sub_actividades":[
                            
                        ],
                        "popover":false,
                        "ind":0,
                        "desface_dias":0,
                        "desface_fin":9,
                        "desplegable":false
                        }
                    ],
                    "popover":false,
                    "popoverInterno":false,
                    "desplegable":false
                },
                {
                "id":1683,
                "estado":0,
                "nivel":2,
                "id_padre":1676,
                "calendario_principal":null,
                "calendario_visible":null,
                "nombre":"BackEnd-Work",
                "posicion":4,
                "descripcion":null,
                "fecha_inicio":null,
                "fecha_fin":null,
                "fecha_inicio_ejecucion":null,
                "fecha_fin_ejecucion":null,
                "id_actividad_predecesora":null,
                "predecesora_duracion":null,
                "predecesora_dias_fin":null,
                "meta_unidad":null,
                "meta_cantidad":null,
                "porcentaje":0,
                "barra_color":"#0b56a7",
                "id_proyecto":null,
                "calendario_sugerido":1,
                "dia_inicio":null,
                "dias_duracion":null,
                "timeline":0,
                "created_by":1,
                "updated_by":null,
                "created_at":"2021-04-16 15:31:06",
                "updated_at":"2021-04-16 15:31:06",
                "deleted_at":null,
                "inicio":15,
                "fin":20,
                "inicio_ejecucion":11,
                "fin_ejecucion":16,
                "hasta":15,
                "hasta_ejecucion":11,
                "actividades":[
                    {
                    "id":1685,
                    "estado":0,
                    "nivel":3,
                    "id_padre":1683,
                    "calendario_principal":null,
                    "calendario_visible":null,
                    "nombre":"Backing",
                    "posicion":1,
                    "descripcion":"Back rol in work!!!",
                    "fecha_inicio":"2021-04-12",
                    "fecha_fin":"2021-04-17",
                    "fecha_inicio_ejecucion":"2021-04-16",
                    "fecha_fin_ejecucion":"2021-04-21",
                    "id_actividad_predecesora":null,
                    "predecesora_duracion":null,
                    "predecesora_dias_fin":null,
                    "meta_unidad":null,
                    "meta_cantidad":null,
                    "porcentaje":0,
                    "barra_color":"#54B2B2",
                    "id_proyecto":null,
                    "calendario_sugerido":1,
                    "dia_inicio":null,
                    "dias_duracion":null,
                    "timeline":0,
                    "created_by":1,
                    "updated_by":null,
                    "created_at":"2021-04-16 15:32:32",
                    "updated_at":"2021-04-16 15:34:55",
                    "deleted_at":null,
                    "encargados_count":0,
                    "comentarios_count":0,
                    "inicio":11,
                    "fin":16,
                    "inicio_ejecucion":15,
                    "fin_ejecucion":20,
                    "hasta":11,
                    "hasta_ejecucion":15,
                    "resto_encargados":-3,
                    "usuarios":[
                        
                    ],
                    "sub_actividades":[
                        
                    ],
                    "popover":false,
                    "ind":0,
                    "desface_dias":0,
                    "desface_fin":4,
                    "desplegable":false
                    }
                ],
                "popover":false,
                "popoverInterno":false,
                "desplegable":false
                },
                {
                "id":1686,
                "estado":0,
                "nivel":2,
                "id_padre":1676,
                "calendario_principal":null,
                "calendario_visible":null,
                "nombre":"Deploy",
                "posicion":5,
                "descripcion":null,
                "fecha_inicio":null,
                "fecha_fin":null,
                "fecha_inicio_ejecucion":null,
                "fecha_fin_ejecucion":null,
                "id_actividad_predecesora":null,
                "predecesora_duracion":null,
                "predecesora_dias_fin":null,
                "meta_unidad":null,
                "meta_cantidad":null,
                "porcentaje":0,
                "barra_color":"#0b56a7",
                "id_proyecto":null,
                "calendario_sugerido":1,
                "dia_inicio":null,
                "dias_duracion":null,
                "timeline":0,
                "created_by":1,
                "updated_by":null,
                "created_at":"2021-04-16 15:32:41",
                "updated_at":"2021-04-16 15:32:41",
                "deleted_at":null,
                "inicio":15,
                "fin":19,
                "inicio_ejecucion":15,
                "fin_ejecucion":19,
                "hasta":15,
                "hasta_ejecucion":15,
                "actividades":[
                    {
                    "id":1687,
                    "estado":0,
                    "nivel":3,
                    "id_padre":1686,
                    "calendario_principal":null,
                    "calendario_visible":null,
                    "nombre":"Testing and Deploiment",
                    "posicion":1,
                    "descripcion":"Testing and deploy in progress.",
                    "fecha_inicio":"2021-04-16",
                    "fecha_fin":"2021-04-20",
                    "fecha_inicio_ejecucion":"2021-04-16",
                    "fecha_fin_ejecucion":"2021-04-20",
                    "id_actividad_predecesora":null,
                    "predecesora_duracion":null,
                    "predecesora_dias_fin":null,
                    "meta_unidad":null,
                    "meta_cantidad":null,
                    "porcentaje":0,
                    "barra_color":"#5ED7B1",
                    "id_proyecto":null,
                    "calendario_sugerido":1,
                    "dia_inicio":null,
                    "dias_duracion":null,
                    "timeline":0,
                    "created_by":1,
                    "updated_by":null,
                    "created_at":"2021-04-16 15:33:27",
                    "updated_at":"2021-04-16 15:34:55",
                    "deleted_at":null,
                    "encargados_count":0,
                    "comentarios_count":0,
                    "inicio":15,
                    "fin":19,
                    "inicio_ejecucion":15,
                    "fin_ejecucion":19,
                    "hasta":15,
                    "hasta_ejecucion":15,
                    "resto_encargados":-3,
                    "usuarios":[
                        
                    ],
                    "sub_actividades":[
                        
                    ],
                    "popover":false,
                    "ind":0,
                    "desface_dias":0,
                    "desface_fin":0,
                    "desplegable":false
                    }
                ],
                "popover":false,
                "popoverInterno":false,
                "desplegable":false
                },
                {
                "id":1710,
                "estado":0,
                "nivel":2,
                "id_padre":1676,
                "calendario_principal":null,
                "calendario_visible":null,
                "nombre":"k",
                "posicion":6,
                "descripcion":null,
                "fecha_inicio":null,
                "fecha_fin":null,
                "fecha_inicio_ejecucion":null,
                "fecha_fin_ejecucion":null,
                "id_actividad_predecesora":null,
                "predecesora_duracion":null,
                "predecesora_dias_fin":null,
                "meta_unidad":null,
                "meta_cantidad":null,
                "porcentaje":0,
                "barra_color":"#0b56a7",
                "id_proyecto":null,
                "calendario_sugerido":1,
                "dia_inicio":null,
                "dias_duracion":null,
                "timeline":0,
                "created_by":167,
                "updated_by":null,
                "created_at":"2021-08-25 16:47:23",
                "updated_at":"2021-08-25 16:47:23",
                "deleted_at":null,
                "actividades":[
                    {
                    "id":1711,
                    "estado":0,
                    "nivel":3,
                    "id_padre":1710,
                    "calendario_principal":null,
                    "calendario_visible":null,
                    "nombre":"jihnoklnio",
                    "posicion":1,
                    "descripcion":"iohklnokl.",
                    "fecha_inicio":"2021-08-04",
                    "fecha_fin":"2021-09-09",
                    "fecha_inicio_ejecucion":"2021-08-04",
                    "fecha_fin_ejecucion":"2021-09-09",
                    "id_actividad_predecesora":null,
                    "predecesora_duracion":null,
                    "predecesora_dias_fin":null,
                    "meta_unidad":null,
                    "meta_cantidad":null,
                    "porcentaje":0,
                    "barra_color":"#000000",
                    "id_proyecto":null,
                    "calendario_sugerido":1,
                    "dia_inicio":null,
                    "dias_duracion":null,
                    "timeline":0,
                    "created_by":167,
                    "updated_by":null,
                    "created_at":"2021-08-25 16:47:52",
                    "updated_at":"2021-08-25 16:47:52",
                    "deleted_at":null,
                    "encargados_count":0,
                    "comentarios_count":0,
                    "sub_actividades":[
                        
                    ],
                    "popover":false,
                    "ind":0,
                    "desface_dias":"__vue_devtool_nan__",
                    "desface_fin":"__vue_devtool_nan__",
                    "desplegable":false
                    },
                    {
                    "id":1712,
                    "estado":0,
                    "nivel":3,
                    "id_padre":1710,
                    "calendario_principal":null,
                    "calendario_visible":null,
                    "nombre":"f",
                    "posicion":2,
                    "descripcion":"fvgbhjnkml",
                    "fecha_inicio":"2021-08-03",
                    "fecha_fin":"2021-09-07",
                    "fecha_inicio_ejecucion":"2021-08-03",
                    "fecha_fin_ejecucion":"2021-09-07",
                    "id_actividad_predecesora":null,
                    "predecesora_duracion":null,
                    "predecesora_dias_fin":null,
                    "meta_unidad":null,
                    "meta_cantidad":null,
                    "porcentaje":0,
                    "barra_color":"#000000",
                    "id_proyecto":null,
                    "calendario_sugerido":1,
                    "dia_inicio":null,
                    "dias_duracion":null,
                    "timeline":0,
                    "created_by":167,
                    "updated_by":null,
                    "created_at":"2021-08-25 16:48:25",
                    "updated_at":"2021-08-25 16:48:25",
                    "deleted_at":null,
                    "encargados_count":0,
                    "comentarios_count":0,
                    "sub_actividades":[
                        
                    ],
                    "popover":false,
                    "ind":0,
                    "desface_dias":"__vue_devtool_nan__",
                    "desface_fin":"__vue_devtool_nan__",
                    "desplegable":false
                    }
                ],
                "popover":false,
                "popoverInterno":false,
                "desplegable":false
                }
            ]
        }
    },
    mounted(){
        
    },
    methods:{
        crearActividad(){
            this.$refs.crearActividad.toggle()
        },
        editarGrupoActividades(name){
            this.$refs.editarGrupoActividades.toggle(name)
        },
        eliminarGrupoActividades(){
            this.$refs.eliminarGrupoActividades.toggle()
        },
        editarActividad(){
            this.$refs.editarActividad.toggle()
        },
        eliminarActividad(){
            this.$refs.eliminarActividad.toggle()
        },
        watchZoom(){
        }
    }
}
</script>

<style lang="scss" scoped>
.calendar-gant-type{
}
    .width-gantt{
        width: auto !important;
    }
    .space-hijo{
        margin-left: 5px;
    }
    .border-active {
        border-bottom: 4px solid var(--color-general)!important;
    }
    .width-act{
        width: 300px;
        left: 0px;
        z-index: 1;
    }
    .width-enc{
        width: 100px;
        left: 300px;
        z-index: 1;
    }
    .width-pro{
        width: 117px;
        left: 300px;
        z-index: 1;
    }
    .width-day{
        min-width: 19px;
        height: 6px;
    }
    .width-day-num{
        min-width: 19px;
        min-height: 15px;
    }
    .bg-red{
        background: #EF2A2A;
    }
    .hover-avance:hover{
        background: var(--box);
        border: 1px solid var(--bg-gris)
    }
    .bg-gris-oscuro {
        background-color: rgba(56, 56, 56, 0.397);
    }
    .h-26{
        height: 35.38px;
    }

    .borde-inicial{
        border-radius: 5px 0px 0px 5px;
    }
    .borde-final{
        border-radius: 0px 5px 5px 0px;
    }
    .borde-redondo{
        border-radius: 5px;
    }

    .bg-grupo{
        background-color: #F5F5F5 !important;
    }
    .bg-grupo-borde{
        border-top: #DBDBDB 1px solid;
        border-bottom: #DBDBDB 1px solid;
    }
    .cr-default{
        cursor: default;
    }

    .width-table{
        width: auto !important;
    }
    .width-gantt{
        width: auto !important;
    }
    .width-fechas{
        width: 100%;
        min-width: 163.2px;
    }
    .hover-add:hover{
        color: var(--color-general)
    }
    @media (min-width: 991px) {
        .width-table{
            width: fit-content !important;
        }
        .width-gantt{
            width: fit-content !important;
        }
    }
    @media (min-width: 1600px) {
        .width-table{
            width: auto !important;
        }
        .width-gantt{
            width: fit-content !important;
        }
    }
    .bar-container{
        position: relative;
        cursor: default;
        height: 15px;
        .bar{
            border-radius: 3px;
            width: 100%;
            height: 100%;
            opacity: .5;
        }
        .percentage{
            position: absolute;
            border-radius: 3px;
            height: 100%;
            opacity: 1;
            top: 0;
            left: 0;
        }
        .timeline-icon{
            display: grid;
            color: white;
            height: 15px;
            width: 15px;
            border-radius: 50%;
            position: absolute;
            cursor: default;
            top: -5px;
            left: -2px;
        }
    }

::-webkit-scrollbar {
  width: 20px;
  height: 15px;
}

/* Fondo scroll */
::-webkit-scrollbar-track {
  background-color: #DBDBDB;
  border-radius: 4px;
}

/* Barra scroll */
::-webkit-scrollbar-thumb {
  background-color: var(--color-general);
  border-radius: 4px;
}

/* Botones */
::-webkit-scrollbar-button{
    border-radius: 3px;
    width: 20px;
    background-color: var(--color-general);
    background-repeat: no-repeat;
    background-size: contain;
    background-attachment: scroll;
    cursor: pointer;

    /* Turn on single button up on top, and down on bottom */
    &:start:decrement, &:end:increment {
        display: block;
        position: absolute;
    }
    /* Turn off the down area up on top, and up area on bottom */
    &:start:increment, &:end:decrement {
        display: none;
    }

    &:end:increment {
        background-image: url(/img/generales/arrow-left-box.png) !important;
    }
    &:start:decrement {
        background-image: url(/img/generales/arrow-right-box.png) !important;
    }
}
</style>